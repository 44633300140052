.fm-main {
    display: flex;
    height: 100%;
}

.fm-search {
    flex:1;
    height: 34px;
    border-radius: 20px;
    border: 1px solid #5C5C5C;
}

.fm-search>input {
    outline: none;
    border: none;
}

.fm-icon {
    width: 24px;
    height: 24px;
    margin: 5px 20px 5px 11px;
}

.icon-btn{
    width: 24px;
    height: 24px;
    color: #5C5C5C;
    padding: 0;
}

.fm-modal-title {
    font-size: 14px;
    font-weight: 600;
}

.fm-modal .modal-content {
    border-radius: 20px;
    width: 1157px;
}

.fm-modal-body {
    padding: 0px;
}

.left-menu {
    border-radius: 0px 0px 0px 20px;
    background: #1b7d7833;
    width: 199px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upload-media {
    background-color: #1b7d78;
    color: white;
    border-radius: 20px;
    margin-top: 30px;
    margin-left: 26px;
    margin-bottom: 93px;
    width: 142px;
}
.upload-media-1 {
    background-color: #1b7d78;
    color: white;
    border-radius: 20px;
    width: 142px;
}

.manage {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 10px;
}

.manage-options {
    padding: 9px 142px 8px 20px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.manage-options.active-tab{
    background-color: #FFC6F2;
}

.manage-options:hover {
    background-color: #FFC6F2;
}

.custom-progress-bar {
    height: 2px;
}

.custom-progress-bar .progress-bar {
    background-color: #1b7d78;
    color: #A2A2A2;
}

.top-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.top-menu-main {
    border-right: 1px solid #ddd;
    display: flex;
    padding: 20px;
    flex-direction: column;
    padding-bottom: 0px;
    flex:1;
}

.top-menu-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.file-details {
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.files-folders {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    color: #1b7d78;
    height: calc(100vh - 300px);
    overflow-y: scroll;
}

.file-folder {
    width: 20%;
    height: 131px;
    margin-bottom:30px;
}

.file-folder.active{
    text-align:center;
    border: 1px solid #1b7d78;
}

.file-folder:nth-of-type(1) {
    border: none;
}

.file-info{
    font-size: 14px;
    font-weight: 600;
    color: #5C5C5C;
    margin-bottom: 20px;
}

.fm-details{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    color: #5C5C5C;
    margin-bottom: 16px;
}
.fm-details-img {
    width: 131px;
    height: 131px;
    border: 1px solid #1b7d78;
    margin: 10px auto 10px auto;
}

.fm-img-title{
    width: 131px;
    font-size: 10px;
    font-weight: 400;
    color: #5C5C5C;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fm-details-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5c5c5c;
}

.top-menu-children {
    display: flex;
    flex: 1;
}

.add-to-page {
    box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.25);
    height: 75px;
    border-radius: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.add-to-page>.btn {
    border-radius: 22px;
    background: #1b7d78;
    color: white;
    margin-right: 20px;
    margin-left: auto;
}

.memory-used{
    font-size: 12px;
    font-weight: 600;
    margin: 20px 10px 20px 20px;
}
.file-folder {
    position: relative;
    margin: 10px; /* Add margin as needed */
}

.vertical-dots {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.file-folder:hover .vertical-dots,
.vertical-dots-popover:hover {
    opacity: 1;
}

.vertical-dots-popover {
    position: absolute;
    color:#000;
    top:0;
    left: 80%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.35);
    padding: 12px;
    width: 240px;
    z-index: 2; /* Ensure popover is on top */
    display: flex;
    justify-content: left;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.file-folder:hover .vertical-dots-popover {
    opacity: 1;
    visibility: visible;
}
