.icon-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}

.horizontal_line {
    color: #5C5C5C;
    margin-top: 30px;
    margin-bottom: 30px;
}

.radio-opoto {
    width: 20px;
    height: 20px;
    color: #1b7d78;
    margin-right: 12px;
}

.checkbox-opto {
    width: 16px;
    height: 16px;
    color: #1b7d78;
}

.rectangle {
    border: 1px solid #DDD;
    background: white;
    color: black;
    width: 90px;
    height: 37px;
}

.square {
    text-align: center;
    border: 1px solid #DDD;
    border-radius: 4px;
    background: white;
    color: black;
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.square.selected {
    border: 1px solid #1b7d78;
    border-radius: 4px;
    color: #1b7d78;
}

.AddModality-Manage {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 4px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.cross-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    /* Add more styling as needed */
}

.manageMain {
    margin-top: 37px;
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 17px;
    padding-bottom: 16px;
}

.image-item {
    width: 100%;
    height: 100%;
    padding: 15px;
}

.text-block-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid;
}

.text-block-end {
    border: 1px solid;
    margin-top: 10px;
}

.backgroun-upload {
    border-radius: 5px;
    border: 1px dashed #1b7d78;
    padding: 42px;
    background: #FFF3FC;
}

.upload-multiple {
    height: 118px;
    width: 118px;
    border-radius: 10px;
    background: #1b7d7833;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-width {
    width: 24px;
    height: 24px;
}

.custom-modal-width {
    max-width: 1200px;
    /* Adjust the width as needed */
    width: 100%;
}

.drag-style {
    font-family: "inter";
    color: #000;
    opacity: 0.5;
}

.main-container-folder {
    flex: 1 1;
    background-color: #1b7d7833;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    border: 2px solid #000;
    border-style: dashed;
}

.upload-plus {
    width: 78px;
    height: 78px;
    border-radius: 10px;
    border: 0.5px dashed #1b7d78;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-container {
    display: flex;
    gap: 30px
}

.image-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

.flex-item-image {
    width: 118px;
    height: 118px;
    border-radius: 10px;
    border: 0.5px solid #C7C7C7;
    display: flex;
    padding: 5px;
    justify-content: center;
}

.custom-toast {
    position: absolute;
    bottom: 20px;
    z-index: 1000;
}

.image-taost {
    height: 24px;
    width: 24px;
}

.style-taost-para {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.style-taost-p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 131px;
}

.grid-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.style-icon-activity {
    width: 18px;
    height: 18px;
}

.display-head {
    border-bottom: 1px solid #DDD;
    padding: 15px
}

.flexitem {
    display: flex;
    border: 1px solid #DDD;
}

.input-control {
    width: 100%;
    /* Set the width to 100% */
    border: aliceblue;
    /* Add a border */
    border-radius: 4px;
    /* Add rounded corners */
}

.margin-inventry {
    margin-bottom: 100px;
}

.input-control:focus {
    outline: none;
    /* Remove the border on focus */
}

.dashboard-subtitle-custom {
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    padding-top: 8px;
}

.btn-add-module {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-top: 92px;
    border: 1px solid #5C5C5C;
    padding-bottom: 92px;
}

.module-border {
    border: 1px solid #5C5C5C;
    padding: 11px;
    height: 219px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.manageshow {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #5C5C5C;

}

.managenumbermain {
    width: 63px;
    height: 23px;
    border: 0.5px solid #CACACA;
    border-radius: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

.managenumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;

}

.manageimage {
    max-height: 18px;
    margin-right: 16px;
    cursor: pointer;
}

.tableheader {
    max-width: 26px;
    max-height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #1D273B;
    background: #F0F8FF;
    border: 1px solid #CACACA;
}

.tablemargin {
    padding-top: 10px;
    padding-bottom: 10px;
}

.managePages {
    background: #F0F8FF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 21px;
    padding-bottom: 21px;
}

.managetabledata {
    background: #FFFFFF;
    border: 1px solid #CACACA;
    height: 56px;
}

.showingEntries {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;
    height: 17px;
}

.managebutton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    margin: 34px;
    color: #CECECE;

}

.managebuttonnumber {
    width: 7px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-right: 16px;

    color: #5C5C5C;
}

.managefilters {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;


    color: #1D273B;
}

.managefilters1 {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #00769B;
    margin-left: 12px;
}

.modalinput {
    width: 136px;
    height: 49px;
    border: 1px solid #8F8F8F;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-right: 16px;
}

.location-icon {
    width: 10px;
    height: 10px;
    color: #5C5C5C;
}
.client-type {
    align-items: center;
    border-radius: 5px;
    display: flex;
    font-size: 10px;
    justify-content: space-between;
    padding: 5px;
    text-align: center;
    width: -webkit-max-content;
    width: max-content;
    background: rgba(27, 125, 120, .2);
    color: #1b7d78;
}
.location-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    margin-left: 12px;
    color: #5C5C5C;

}

.location-text:hover {
    color: #00769B;
}

.tick-icon {
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.copyright-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    margin-left: 14px;
    color: #057697;
}

.users-modal {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    margin-top: 20px;
    background-color: #00769B;
}

.dashboard-2 {
    color: #057697;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.dashboard-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding-top: 8px;
    cursor: pointer;
}

.addimage {
    width: 14px;
    height: 14px;
}

.deletefields {
    width: 14px;
    height: 2px;
}

.Client-main {
    margin-top: 30px;
}

.client-sub-main {
    margin-top: 60px;
}

.upload-Download {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #00769B;
    cursor: pointer;
}

.f-copy {
    color: #057697;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 112px;
}

.uploadBox {
    border: 1px dashed #00769B;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #00769B;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    cursor: pointer;
}

.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
}

.table-wrapper-scroll-y {
    display: block;
}

.uploadBox>input {
    display: none;
}

.radioupload>input {
    display: none;
}

.radio-tags {
    margin-right: 20px
}

.upload-head {
    margin-top: 36px;
}

.radio-tags>p.radio-ptag {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
}

.radio-formcheck {
    margin-top: 48px;
}

.rdt_TableCol {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.search-form {
    padding: 20px 0px;
}

.search-dropdowns p {
    margin-bottom: 0px;
    padding: 2px 15px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.search-dropdowns p:hover {
    background: #f4fafe;
    color: #00769B;
}

.search-dropdowns {
    border: 1px solid #ddd;
    border-top: none;
}

.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    overflow-x: auto;
}

.ClientTariff-filter {
    margin-right: 20px;
}

.accordion-container {
    margin-top: 20px;
}

.accordion-body .container-form {
    padding-top: 0px;
}

.TariffModal {
    display: flex;
    justify-content: end;
    margin: 12px
}

.rowsPerpage {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 4px;
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.serPerpage {
    border: none;
    color: inherit;
    background-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}

.serPerpage:hover {
    border: black;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-inline label {
    min-width: 200px;
}

.sidebar-child-container {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 1px;
}

::-webkit-scrollbar-track-piece {
    background-color: #eef7fe;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: rgba(27, 125, 120, .2);
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
    height: 50px;
    background-color: rgba(27, 125, 120, .2);
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

.loader-container {
    text-align: center;
}

.loader-container img {
    width: 100px;
    padding-top: 60px;
}

.upload-Certificates {
    margin-top: 40px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-icon {
    fill: currentColor;
    cursor: pointer;
}

.table-error {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    padding-top: 60px;
    color: #6c757d;
    text-decoration-line: underline;
}

.clientbill-info {
    background-color: #EEF7FF;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #007698;
}

.tablescroll {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 50px;
}

.clientbill-info p {
    margin-bottom: 4px;
}

.form-control.form-discount {
    height: 30px;
    margin-bottom: 8px;
}

.table>thead {
    text-align: "start";
    background-color: #EEF7FF;
    color: #007698;
}

th {
    font-weight: 700;
    line-height: 24px;
    font-size: 12px;
    text-transform: uppercase;
}

.table>tbody {
    font-size: 13px;
    font-weight: 400;
}

.billingModality {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.action-title {
    font-weight: bold;
    font-size: 8px;
    line-height: 20px;
}

.specialities-main {
    margin-top: 20px;
}

.Specialities-master {
    border: 2px solid #888888;
    border-radius: 4px;
    padding-left: 14px;
    padding-top: 15px;
    padding-bottom: 22px;
    padding-right: 8px;
}

.specialties-mas.inactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
    padding: 8px;
    border: 2px solid #888888;
    border-radius: 4px;
}

.specialties-mas.Acitve {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #409D9B;
    border-radius: 4px;
    margin-top: 23px;
    padding: 8px;
    font-weight: bold;
}

.clinicspeciality.acitve {
    color: green;
}

.clinicspeciality.inacitve {
    color: red;
}

.specialties-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
    padding: 8px;
    border: 2px solid #888888;
    border-radius: 4px;
}

.row-check {
    padding: 5px;
    border: 2px solid #007698;
}

.Doctor-main {
    margin-top: 23px;
}

.time-input {
    border-top: none;
    border-left: none;
    border-right: none;
}

.delete {
    border: none;
    border-bottom: none;
}

.postion-finish {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.acitve-image {
    opacity: 0.5;
    background: rgb(0, 0, 0);
    color: white;
}

.input-group-append-custom {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid #DDD;
    border-top: 1px solid #DDD;
    border-right: 1px solid #DDD;

}

/* CustomDropdown.css */

.menu__link {
    padding: 10px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
}

.menu__link:hover {
    background-color: #f0f0f0;
}

.menu__link--sublist-caret::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #5C5C5C;
    /* Black background color */
    margin-left: 5px;
    mask-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><path fill="%23000" d="M0 2l4 4 4-4H0z"/></svg>');
    /* Down-pointing arrow */
    mask-size: cover;
    /* Ensures the mask covers the entire element */
    transition: transform 0.3s ease-out;
}
.success {
    color: var(--bs-success-text, #28a745); /* Default Bootstrap success green */
}
.fixed-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000; /* Ensure it is above other content */
  }
  
.client-type.contract-background,
.contract-background {
    background: rgba(27, 125, 120, .2);
    color: #1b7d78;
}

.client-type {
    align-items: center;
    border-radius: 5px;
    display: flex;
    font-size: 10px;
    justify-content: space-between;
    padding: 5px;
    text-align: center;
    width: -webkit-max-content;
    width: max-content;
}

.menu__link--sublist-caret.open::after {
    transform: rotate(180deg);
}


.menu__list-item-collapsible ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border: 1px solid #ddd;
}

.menu__list-item-collapsible ul.show {
    max-height: 200px;
    overflow-y: auto;
}

.menu__list-item-collapsible ul li {
    display: block;
    margin-left: 39px;
}

.card-ma div {
    margin-bottom: 8px;
}

.card-ma {
    margin-bottom: 8px;
    margin-top: 8px;
}

.menu__list-item-collapsible ul li a {
    display: block;
    padding: 10px;
    background-color: #fff;
    text-decoration: none;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
}

.menu__list-item-collapsible ul li a:hover {
    background-color: #f0f0f0;
}

.menu__list-item-collapsible ul li a.active {
    background-color: #007bff;
    color: white;
}

.dropdown-item {
    padding: 10px;
    background-color: #fff;
    text-decoration: none;
    color: #333;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.input-box {
    position: relative;
    display: flex;
    align-items: center;
}

.color-box {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.custom-input-class {
    padding-left: 35px;
    /* Adjust the padding to create space for the colored box */
}

.color-box div {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.class-border {
    border-right: none;
}

.custom-input {
    border-radius: 0;
    /* Set border-radius to 0 for custom input */
}

.table td.Cross {
    border: none;
}

.RxCross {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
}

.complaints-container {
    position: relative;
    border: 1px solid #ddd;
    padding: 15px;
}

h3.timeline-pastvisits {
    margin-bottom: 0;
    margin-top: 10px;
    padding: 7px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    background-color: #007698;
}

.invalid-error {
    border: 1px solid var(--bs-danger-text, #ff0000);
    /* Fallback to #ff0000 if variable is not defined */
    border-radius: 4px;
}

.invalid {
    color: var(--bs-danger-text, #ff0000);
}

.input-box>div {
    flex: 1 1;
}

.complaints-dropdown-show {
    position: absolute;
    left: 6%;
    height: 250px;
    /* width: calc(100% - 40px); */
    width: 94%;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    z-index: 4;
}

.complaints-dropdown-hide {
    display: none;
}

.emr-data-container {
    position: relative;
}

.rbc-addons-dnd.rbc-calendar {
    width: calc(100vw - 300px);
    height: calc(100vh - 50px);
}

.rbc-timeslot-group {
    min-height: 200px !important;
}

/* .rbc-events-container.rbc-event.rbc-addons-dnd-resizable{
   display: grid;
   grid-template-columns: auto auto;
}  */
.rbc-events-container .rbc-event {
    width: 50px !important;
    left: 0px !important;
    height: auto;
}

.emr-dropdown {
    height: 250px;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    z-index: 4;
}

.emr-list {
    line-height: 25px;
}

.list-category:nth-of-type(1) {
    overflow-y: scroll;
}

p.tb-selector-title {
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
}

p.tb-selector-title:hover {
    background-color: #ddd;
}



.colitem {
    overflow-y: auto;
    max-height: 240px;
}

.row-item {
    margin-bottom: 10px;
}

.emr-form-icon {
    font-size: 24px;
}

.row-df {
    display: flex;
    align-items: center;
}

.select-div {
    width: 160px;
}


.patient-info {
    display: flex;
}

svg.user-icon {
    font-size: 50px;
}

.end {
    flex: 9;
}

.end button {
    float: right;
}

.rs {
    padding-left: 10px;
}

.set-of-rows {
    margin-bottom: 40px;
}

.hx-vitalFields {
    border: 1px solid #DFE1E2;
}

.vital-input {
    font-size: 14px;
    line-height: 1.429;
    color: #243951;
    border-radius: 4px;
    padding: 5px 12px;
}

.hx-vitalUnit {
    text-align: center;
    width: 50px;
    height: 32px;
    background: #F8F8F8;
    border: 1px solid #DFE1E2;
    border-radius: 0px 4px 4px 0px;
    right: 0;
    line-height: 2.15;
}

.vital-input-bp {
    font-size: 14px;
    line-height: 1.429;
    color: #243951;
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
}

.sidebar-child-emr {
    background: #dbefffc1;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
}

.mainbar {
    display: flex;
    margin-top: -12px;
    margin-left: -18px;
}

.sidebar-filters {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    padding: 10px 15px;
    margin-left: 10px;
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.main-code {
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: auto;
}

.dashboard-content {
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.dashboard-content::-webkit-scrollbar {
    display: none;
}

.btn-filter {
    width: 100%;
}

.showing-from-date {
    padding: 12px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    background-color: #E9EAF0;
    margin-top: 10px;
}

.report-id {
    color: #0F1938;
    font-size: 12px;
    font-weight: 500;
}

.table-padding {
    padding-top: 16px;
    padding-bottom: 16px;
}

.order.Delivered {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00B37A;
    background-color: #E6F8F2;
}

.btn-button-filter {
    margin-top: 10px;
}

.merchant-details {
    margin-top: 16px;
}

.sidebar-filters-reports {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 320px;
    padding: 10px 15px;
    margin-left: 10px;
    height: 100vh;
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.number-count {
    font-weight: 700;
}

.merchant-money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 30px;
}

.merchant-total-money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 30px;
    color: black;
    font-weight: 500;
}

.merchant-summary {
    color: #6F7588;
    font-size: 14px;
    margin-top: 12px;
}

.marchant-pay {
    font-size: 20px;
    font-weight: 700;
}

.order.cancelled {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #E24C4B;
    background-color: #FDEEED;
}

.sidebar-filters1 {
    /* flex: 1; */
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    padding: 10px 15px;
    margin-left: 10px;
    height: 100vh;
}

.main-code {
    flex: 1;
    padding: 10px;
}

.pie-height {
    height: 349px !important;
}

/* calender styles */
.cal-header {
    display: flex;
    justify-content: space-around;
    border: 1px solid #ddd;
    padding: 5px 0px;
    align-items: center;
}

.date-selection {
    display: flex;
}

.calender-main-container {
    border: 1px solid #ddd;
    overflow-x: scroll;
    max-width: calc(100vw - 300px);
}

.cal-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
}

.cal-con-blocks {
    flex: 9;
}

.cal-con-doctors {
    flex: 1 1;
    height: 90px;
    width: 140px;
    overflow-y: auto;
}

.cal-con-body p {
    margin-bottom: 0px;
}

.cal-con-availability .cal-con-body p {
    font-size: 12px;
}

.calender-main-container {
    padding-top: 10px;
}

.cal-con-availability {
    flex: 1;
}

.calender-time-blocks {
    display: flex;
    justify-content: space-between;
}

.cal-block-itm {
    width: 70px;
    margin: 0;
}

.cal-con-head {
    border-bottom: 1px solid #ddd;
}




.slots-div {
    height: 70px;
    display: grid;
    grid-template-columns: auto auto auto;
}

.slot-item {
    margin: 2px;
}

.btn-slot:hover {
    border: 1px solid #000;
}

.btn-slot {
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-green {
    background-color: green;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-red {
    background-color: red;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.btn-grey {
    background-color: gray;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-red {
    background-color: red;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.visit-type {
    margin-left: 50px;
}

.calender-vertical-layout {
    padding-left: 90px;
}

.calender-doctor {
    display: flex;
    align-content: space-between;
}

.calender-timeslots {
    flex: 1;
}

.cal-ver-itm {
    border-bottom: 1px solid #ddd;
    position: relative;
}

.calender-vertical-item {
    flex: 1;
}

.cal-con-blocks {
    border-top: 1px solid #ddd;
    padding-top: 0px;
}

.cal-con-availability p {
    margin: 0;
}

p.cal-block-itm {
    position: absolute;
    left: -80px;
    top: 30px;

}

img.doctor-dp {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

/* end calender styles */
.search-drop {
    z-index: 5000;
}

.tick-icon {
    color: green;
}

.icon {
    color: black;
}

.back-app {
    color: black;
}

.template {
    display: flex;
    justify-content: end;
    align-items: center;
}

.save-button {
    margin-top: 10px;
}

/* Past Visits */
.timeline {
    background-color: #474e5d;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}


.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}


.past {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}


.past::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    background-color: white;
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}


.left {
    left: 0;
}


.right {
    left: 50%;
}


.left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}


.right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}


.right::after {
    left: -16px;
}


.content {
    background-color: white;
    position: relative;
    border-radius: 6px;
}


@media screen and (max-width: 600px) {

    .timeline::after {
        left: 31px;
    }


    .past {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }


    .past::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }


    .left::after,
    .right::after {
        left: 15px;
    }


    .right {
        left: 0%;
    }
}

.form-check-label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
}

.form-switch {
    padding-left: 2.5em;
    margin-bottom: 16px;
}

.color-input{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 0px;
}

.form-group-color{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ico-emr {
    font-size: 16px;
}

.hrStyle {
    width: 100%;
    border: 4px solid black;
}

.advice-emr {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
}

.count-emr {
    padding-left: 20px;
    padding-right: 20px;
}

.emr-cont-header {
    padding: 12px
}

.emr-count-main {
    background-color: #EEF7FF;
    color: #007698;
    padding: 12px;
}

.time-content {
    padding-left: 12px;
}

.time-content-head {
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.content p {
    margin-bottom: 0;
}

.time-block {
    display: flex;
    align-items: center;
}

.calander-time {
    overflow: scroll;
}

.time-slot-butn {
    margin: 20px;
}

.time-slot-main {
    display: flex;
    align-items: center;
}

.time-slot-butn p {
    text-align: center;
}

.text-time {
    text-decoration: underline;
    cursor: pointer;
}

@media print {
    .printButton {
        display: none !important;
    }

    .ico-emr {
        display: none;
    }

    body {
        font-size: 12px;
    }
}

.prod-manage {
    border: 1px solid #ddd;
}

.prod-item {
    border: 1px solid #ddd;

}

.prod-item img {
    max-width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

.search-list-item {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px 10px;
    background: white;
}

.list-container {
    padding: 0px;
    position: absolute;
    z-index: 2;
    width: 100%;
}

.mng-prod-img {
    width: 69px;
    height: 69px;
}

.store-select {
    z-index: 2;
    position: relative;
    margin-bottom: 10px;
    margin-top: 14px;
}

.prod-search {
    margin-top: 14px;
    display: flex;
}

.prod-search-input {
    width: 98%;
    margin-bottom: 10px;
}

.sync {
    padding: 5px;
}

.mr-1 {
    margin-right: 20px;
}

.ship-cont {
    display: grid;
    justify-content: space-evenly;
    align-items: center;
    /* flex-wrap: wrap; */
    grid-template-columns: auto auto;
}

.shipping-types {
    background: #D1E5E4;
    box-shadow: 0px 4px 7px 0px #00000029;
    width: 147.47px;
    height: 38px;
    border-radius: 5px 0px 0px 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 15.73px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.discount-comment {
    margin-top: 10px;
    color: #2575f0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}

.payment-modee {
    padding: 0px 3px 3px 3px;
    border-radius: 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFA500;
    color: #ffffff;
}
.order-statuss{
    padding: 0px 3px 3px 3px;
    border-radius: 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: #1B7D78;
    color: #ffffff;
}

.sup-colour
{
    color:red;
    font-size: 13px;
}
/* Container for the card list */
/* Flex container for folder header */
.folder-header {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space below the header */
  }
  
  /* Adjust the styles for the number and title */
  .folder-order-number {
    font-size: 1.25rem;
    margin-right: 10px; /* Space between number and title */
  }
  
  .folder-title {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
  }
  
  /* Other styles remain unchanged */
  .folder-card {
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3); /* White shadow */
    transition: box-shadow 0.3s ease;
    padding: 20px;
    margin-bottom:10px;
    cursor: pointer;
  }
  
  .folder-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .folder-card-content {
    text-align: left;
  }
  
  .folder-id {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .message-header {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .folder-message {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  .breadcrumb-container {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Center items vertically */
    font-size: 16px; /* Font size for the breadcrumbs */
    margin: 10px 0; /* Add margin for spacing */
  }
  
  .breadcrumb-item {
    cursor: pointer; /* Change cursor to pointer on hover */
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
    margin-right: 8px; /* Space between items */
  }
  
  .breadcrumb-item:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .breadcrumb-separator {
    margin-right: 8px; /* Space after the separator */
  }
  .options-icon{
    position:absolute;
    top:26px;
    right:5px;
  } 
  
 .options-icon {
    cursor: pointer; /* Change cursor on hover */
    transition: color 0.3s ease; /* Smooth color transition */
    opacity: 0; /* Hide the icon by default */
    visibility: hidden; /* Hide the icon from layout */
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth visibility transition */
  }
  
  .folder-card:hover .options-icon {
    opacity: 1; /* Show the icon on hover */
    visibility: visible; /* Make icon visible */
  }
  .options-card {
    position: absolute;
    top: 45px;
    right: 10px;
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
}
  
  .options-card h5 {
    margin: 0 0 10px; /* Spacing for heading */
  }
  
  .options-card button {
    margin-right: 10px; /* Space between buttons */
    padding: 8px 12px; /* Button padding */
    background-color: #007bff; /* Primary color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .options-card button:hover {
    background-color: #0056b3; /* Darker on hover */
  }
